const envConfig = {
  API_KEY: process.env.REACT_APP_API_KEY ?? 'b05eac16737444afba856d6adc1338d9',
  API_URL: process.env.REACT_APP_API_URL ?? 'https://tgtpr-grocefy.azure-api.net/web',
  HUB_URL: process.env.REACT_APP_HUB_URL ?? 'https://tgtpr-grocecy-prod-api.azurewebsites.net/',
  MAPBOX_TOKEN: process.env.REACT_APP_MAPBOX_TOKEN ?? '#{REACT_APP_MAPBOX_TOKEN}#',
  PAYPAL_CLIENTID: process.env.REACT_APP_PAYPAL_CLIENTID ?? 'AZC-1drMKLIAMP0jM1Pwo7uZCRfrttQm4DizOIjvcWS8RJC1JabOmYewW3sY41jzQAaxacyqWzvrdd-b',
  GEO_API_KEY: process.env.REACT_APP_GEO_API_KEY ?? '#{REACT_APP_GEO_API_KEY}#',
  SearchItemsPageSize: 14,
  StorageKey: process.env.REACT_APP_STORAGE_KEY ?? 'tgt-web', 
  DefaultLanguage: process.env.REACT_APP_DEFAULT_LANGUAGE ?? 'en', 
  TemplateStyle: process.env.REACT_APP_TEMPLATE_STYLE ?? '#{REACT_APP_TEMPLATE_STYLE}#',
  TemplateVersion: process.env.REACT_APP_TEMPLATE_VERSION ?? '2',
  HeaderVersion: process.env.REACT_APP_HEADER_VERSION ?? '2',
  HomeVersion: process.env.REACT_APP_HOME_VERSION ?? '2',
  SubHeaderVersion: process.env.REACT_APP_SUBHEADER_VERSION ?? '2',
  StoreVersion: process.env.REACT_APP_STORE_VERSION ?? '2',
  WelcomeLong: process.env.REACT_APP_WELCOME_LONG_ES ?? 'Bienvenido a Tour Golf & Tennis',
  WelcomeShort: process.env.REACT_APP_WELCOME_SHORT_ES ?? 'Bienvenido a Tour Golf & Tennis',
  WelcomeLongEn: process.env.REACT_APP_WELCOME_LONG_EN ?? 'Welcome to Tour Golf & Tennis',
  WelcomeShortEn: process.env.REACT_APP_WELCOME_SHORT_EN ?? 'Welcome to Tour Golf & Tennis',
  WelcomeLongEs: process.env.REACT_APP_WELCOME_LONG_ES ?? 'Bienvenido a Tour Golf & Tennis',
  WelcomeShortEs: process.env.REACT_APP_WELCOME_SHORT_ES ?? 'Bienvenido a Tour Golf & Tennis',
  DepartmentsStyle: process.env.REACT_APP_DEPARTMENTS_STYLE ?? '#{REACT_APP_DEPARTMENTS_STYLE}#',
  DepartmentsDrawerStyle: process.env.REACT_APP_DEPARTMENTS_DRAWER_STYLE ?? '#{REACT_APP_DEPARTMENTS_DRAWER_STYLE}#',
  DepartmentCarouselSize: 6,
  CardBorderStyle: process.env.REACT_APP_CARD_BORDER_STYLE ?? '#{REACT_APP_CARD_BORDER_STYLE}#',
  ButtonStyle: process.env.REACT_APP_BUTTON_STYLE ?? '#{REACT_APP_BUTTON_STYLE}#',
  SocialFooterVersion: process.env.REACT_APP_SOCIAL_FOOTER_VERSION ?? '#{REACT_APP_SOCIAL_FOOTER_VERSION}#',
  InstagramLink: process.env.REACT_APP_INSTAGRAM_LINK ?? 'https://www.instagram.com/tourgolfandtennis/?hl=en',
  FacebookLink: process.env.REACT_APP_FACEBOOK_LINK ?? 'https://www.facebook.com/tourgolfandtennis/',
  WelcomeVersion: process.env.REACT_APP_WELCOME_VERSION ?? '2',
  FeedbackFooterVersion: process.env.REACT_APP_FEEDBACK_FOOTER_VERSION ?? '#{REACT_APP_FEEDBACK_FOOTER_VERSION}#',
  GoogleAnalyticsTrackingId: process.env.REACT_APP_GA_TRACKING_ID ?? '#{REACT_APP_GA_TRACKING_ID}#',
  ShowShopper: process.env.REACT_APP_SHOW_SHOPPER ?? '#{REACT_APP_SHOW_SHOPPER}#',
  UseLogRocket: process.env.REACT_APP_UseLogRocket ?? '#{UseLogRocket}#',
  CartItemLimit: process.env.REACT_APP_ITEM_LIMIT ?? '#{REACT_APP_ITEM_LIMIT}#',
  ShowShopperButton: process.env.REACT_APP_SHOW_SHOPPER_BUTTON ?? '#{REACT_APP_SHOW_SHOPPER_BUTTON}#',
  FacebookPixelId: process.env.REACT_APP_FACEBOOK_PIXEL_ID ?? '#{REACT_APP_FACEBOOK_PIXEL_ID}#',
  TosUrl: process.env.REACT_APP_TOS_URL ?? '#{REACT_APP_TOS_URL}#',
  ShowEbtBeforePopup: process.env.REACT_APP_SHOW_EBT_BEFORE_POPUP ?? '#{REACT_APP_SHOW_EBT_BEFORE_POPUP}#',
  ShowEbtAfterPopup: process.env.REACT_APP_SHOW_EBT_AFTER_POPUP ?? '#{REACT_APP_SHOW_EBT_AFTER_POPUP}#',
  WebstoreId: process.env.REACT_APP_WEDSTORE_ID ?? '#{REACT_APP_WEBSTORE_ID}#',
  AppName: process.env.REACT_APP_APP_NAME ?? '#{REACT_APP_APP_NAME}#',
  ContactUsSite: process.env.REACT_APP_CONTACT_US_SITE ?? '#{REACT_APP_CONTACT_US_SITE}#',
  ContactUsEmail: process.env.REACT_APP_CONTACT_US_EMAIL ?? 'info@tgt.store',
  ContactUsAddress1: process.env.REACT_APP_CONTACT_US_ADDRESSLINE_1 ?? '#{REACT_APP_CONTACT_US_ADDRESSLINE_1}#',
  ContactUsAddress2: process.env.REACT_APP_CONTACT_US_ADDRESSLINE_2 ?? '#{REACT_APP_CONTACT_US_ADDRESSLINE_2}#',
  ContactUsAddress3: process.env.REACT_APP_CONTACT_US_ADDRESSLINE_3 ?? '#{REACT_APP_CONTACT_US_ADDRESSLINE_3}#',
  SidebarVersion: process.env.REACT_APP_SIDEBAR_VERSION ?? '2',
  CounterVersion: process.env.REACT_APP_COUNTER_VERSION ?? '2',
  ShowUserPayments: process.env.REACT_APP_SHOW_USER_PAYMENTS ?? 'false',
  StripePK: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? 'pk_live_51LTPZ8D4P1D293agaktcHqfEnLpJGTvoCr4bMzNvwoYoUSYAMlQGtv4AFixfD3E1XrkGBf0TEj4QSl7jDp1uAbJB004sfUL7lc',
  BrainTreeTokenizationKey: process.env.REACT_APP_BRAINTREE_TOKENIZATION_KEY ?? '#{REACT_APP_BRAINTREE_TOKENIZATION_KEY}#',
};

export default envConfig;
