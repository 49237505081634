import React, { useState, useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { notificationsAtom, orderAtom, modalAtom, productAtom } from '../../atoms/Atoms';
import envConfig from '../../envConfig';
import useAnalyticsEventTracker from '../../useAnalyticsEventTracker';

function Counter({ product }) {
  const eventTracker = useAnalyticsEventTracker('User Actions');
  const { t } = useTranslation();
  const [count, setCount] = useState(0);
  const [order, setOrder] = useRecoilState(orderAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [modal, setModal] = useRecoilState(modalAtom);
  const [productA, setProduct] = useRecoilState(productAtom);
  const [allowAdd, setAllowAdd] = useState(false);

  useEffect(() => {
    if (order.length > 0) {
      const filter = _.filter(order, (p) => p.id === product.id);
      let amount = 0;
      for (let i = 0; i < filter.length; i++) {
        console.log(filter[0].amount);
        amount += filter[0].amount;
      }
      setCount(amount);
    } else {
      setCount(0);
    }
  }, [order, product]);

  if (count === 0) {
    return (
      <button
        type="button"
        disabled={!product.isAvailable ||
          (productA?.requirements?.length > 0 && !productA?.selectedAllRequirements)}
        onClick={(e) => {
          e.stopPropagation();
          if (!modal && product.requirements?.length > 0) {
            setModal(true);
            setProduct(product);
          } else if (modal && product.requirements?.length > 0) {
            let itemCount = 0;
            for (let i = 0; i < order.length; i++) {
              if (order[i].hasRandomWeight) {
                itemCount++;
              } else {
                itemCount += order[i].amount;
              }
            }
            if (itemCount >= envConfig.CartItemLimit) {
              setNotifications([
                ...notifications,
                {
                  title: t('sorry'),
                  description: t('reached_item_limit'),
                  error: false,
                  timeout: 3000
                },
              ]);
              return;
            }
            const tempProduct = JSON.parse(JSON.stringify(product));
            tempProduct.requirements = productA.requirements;
            setOrder([...order, { ...tempProduct, amount: 1, uuid: uuidv4() }]);
            setNotifications([
              ...notifications,
              {
                title: t('added_to_cart'),
                description: `${product.brand} ${product.name} ${product.description}`,
                error: false,
              },
            ]);
          } else {
            let itemCount = 0;
            for (let i = 0; i < order.length; i++) {
              if (order[i].hasRandomWeight) {
                itemCount++;
              } else {
                itemCount += order[i].amount;
              }
            }
            if (itemCount >= envConfig.CartItemLimit) {
              setNotifications([
                ...notifications,
                {
                  title: t('sorry'),
                  description: t('reached_item_limit'),
                  error: false,
                  timeout: 3000
                },
              ]);
              return;
            }
            const tempProduct = JSON.parse(JSON.stringify(product));
            if (tempProduct.hasRandomWeight) {
              tempProduct.amount = product.minQuantity;
              setOrder([...order, { ...tempProduct, uuid: uuidv4() }]);
            } else {
              let newOrder = order;
              for (let i = 0; i < tempProduct.minQuantity; i++) {
                tempProduct.uuid = uuidv4();
                tempProduct.amount = 1;
                newOrder = newOrder.concat(tempProduct);
              }
              setOrder(newOrder);
              setNotifications([
                ...notifications,
                {
                  title: t('added_to_cart'),
                  description: `${product.brand} ${product.name} ${product.description}`,
                  error: false,
                },
              ]);
            }
          }
        }}
        className="w-24 h-8 text-xs rounded-md font-semibold text-white bg-actionButton disabled:bg-gray-300 disabled:cursor-not-allowed hover:bg-actionButtonHover transition duration-500 ease-in-out outline-none focus:outline-none"
      >
        {product.isAvailable ? t('add') : t('not_available')}
      </button>
    );
  }

  return (
    <div>
      <div className="w-24 flex items-center rounded-md">
        <button
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            if (count !== 0) {
              let newOrder = JSON.parse(JSON.stringify(order));
              if (product.hasRandomWeight) {
                const index = _.findIndex(order, (o) => o.id === product.id);
                if (newOrder[index].amount - product.stepAmount < product.minQuantity) {
                  newOrder = _.filter(order, (p, i) => i !== index);
                } else {
                  newOrder[index].amount -= product.stepAmount;
                }
              } else {
                const tempAmount = _.filter(order, (p) => p.id === product.id).length;
                if (tempAmount - 1 < product.minQuantity) {
                  newOrder = _.filter(order, (p, i) => p.id !== product.id);
                } else {
                  const index = _.findIndex(order, (o) => o.id === product.id);
                  newOrder = _.filter(order, (p, i) => i !== index);
                }
              }
              eventTracker('Remove Item from Cart', product.upc);
              setOrder(newOrder);
              setNotifications([
                ...notifications,
                {
                  title: t('removed_from_cart'),
                  description: `${product.brand} ${product.name} ${product.description}`,
                  error: false,
                },
              ]);
            }
          }}
          className="bg-white w-8 h-8 border rounded-l-md font-semibold text-gray-500 hover:text-gray-400 disabled:cursor-not-allowed transition duration-500 ease-in-out outline-none focus:outline-none"
        >
          -
        </button>
        <div className="bg-white text-xs flex items-center justify-center font-extrabold tracking-tight w-8 h-8 border-t border-b">
          {count}
        </div>
        <button
          type="button"
          disabled={!product.isAvailable}
          onClick={async (e) => {
            e.stopPropagation();
            let itemCount = 0;
            for (let i = 0; i < order.length; i++) {
              if (order[i].hasRandomWeight) {
                itemCount++;
              } else {
                itemCount += order[i].amount;
              }
            }
            if (itemCount >= envConfig.CartItemLimit) {
              setNotifications([
                ...notifications,
                {
                  title: t('sorry'),
                  description: t('reached_item_limit'),
                  error: false,
                  timeout: 3000
                },
              ]);
              return;
            }
            const tempProduct = JSON.parse(JSON.stringify(product));
            const index = _.findIndex(order, (o) => o.id === product.id);
            if (index > -1) {
              if (tempProduct.hasRandomWeight) {
                if (order[index].amount + tempProduct.stepAmount <= tempProduct.maxQuantity) {
                  const newOrder = JSON.parse(JSON.stringify(order));
                  newOrder[index].amount += tempProduct.stepAmount;
                  setOrder(newOrder);
                }
              } else {
                const tempAmount = _.filter(order, (p) => p.id === product.id).length;
                if (tempAmount + 1 <= tempProduct.maxQuantity) {
                  tempProduct.amount = 1;
                  setOrder([...order, { ...tempProduct, uuid: uuidv4() }]);
                }
              }
            }
            eventTracker('Add Item to Cart', product.upc);
            setNotifications([
              ...notifications,
              {
                title: t('added_to_cart'),
                description: `${product.brand} ${product.name} ${product.description}`,
                error: false,
              },
            ]); 
          }}
          className="bg-white w-8 h-8 border rounded-r-md font-semibold text-gray-500 hover:text-gray-400 disabled:cursor-not-allowed transition duration-500 ease-in-out outline-none focus:outline-none"
        >
          +
        </button>
      </div>
      {/* <motion.div
        key="count"
        initial={{ z: 100 }}
        animate={{ z: -100 }}
        transition={{ ease: 'easeOut', duration: 1 }}
        exit={{ z: -100 }}
        className="relative flex items-center justify-center"
      >
        <div className="relative w-24 h-8 flex items-center justify-center 
         bg-base border rounded-md">
          <div className="text-xs text-white text-center">{t('added')}</div>
        </div>
      </motion.div> */}
    </div>
  );
}

export default Counter;
