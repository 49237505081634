import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-duotone-svg-icons';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useClickAway, useLocation } from 'react-use';
import { AnimatePresence, motion } from 'framer-motion';
import { useStripe, Elements, PaymentElement, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import api from '../../../../../../api/api';
import { getType } from '../../../../../../functions';
import { backdropVariants, modalVariants } from '../../../../../../utils';
import {
  notificationsAtom,
  tokenAtom,
  userPaymentsAtom,
} from '../../../../../../atoms/Atoms';
import Input from '../../../../../shared-components/form/Input';
import CleaveInput from '../../../../../shared-components/form/CleaveInput';
import Button from '../../../../../shared-components/buttons/Button';
import Form from '../../../../../shared-components/form/Form';
import envConfig from '../../../../../../envConfig';

// const stripePromise = loadStripe(envConfig.StripePK);

const Modal = (props) => {
  const location = useLocation();
  const stripe = useStripe();
  const elements = useElements();
  const { i18n, t } = useTranslation();
  const ref = useRef();
  const token = useRecoilValue(tokenAtom);
  const [payment, setPayment] = useRecoilState(userPaymentsAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [loading, setLoading] = useState(false);

  const handleStripe = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    // elements.fetchUpdates();

    elements.submit().then(async (submitCheck) => {
      console.log(submitCheck);
      if (!submitCheck.error) {
        const result = await stripe.confirmSetup({
          // `Elements` instance that was used to create the Payment Element
          elements,
          clientSecret: payment.intent,
          redirect: 'if_required',
          confirmParams: {
            return_url: location.href
          },
        });
        setLoading(false);
        if (result.error) {
          setNotifications([
            ...notifications,
            {
              title: t('error'),
              description: result.error.message,
              error: true,
            },
          ]);
        } else {
          setLoading(true);
          api.post('user/stripe/add/paymentmethod', { PaymentMethodId: result.payment_method }).then((response) => {
            if (response.data.error) {
              setNotifications([
                ...notifications,
                {
                  title: t('error'),
                  description: response.data.error,
                  error: true,
                },
              ]);
            } else {
              setLoading(false);
              setPayment({
                ...payment,
                modal: false,
                cards: [...payment.cards, response.data.data],
              });
            }
          }).catch((error) => {
            setNotifications([
              ...notifications,
              {
                title: t('error'),
                description: error.message,
                error: true,
              },
            ]);
          });
        }
      }
    });
  }

  useEffect(() => {
    if (payment.modal && payment.intent) {
      // elements.fetchUpdates();
    }
  }, [payment]);

  useClickAway(ref, () => {
    setPayment({ ...payment, modal: false, intent: null, edit: null });
  });

  return (
    <AnimatePresence>
      {(payment.modal || !payment.modal) && (
        <motion.div
          variants={backdropVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          className={`${payment.modal ? 'fixed' : 'hidden'} bg-gray-800 inset-0 bg-opacity-75 z-10 flex items-center justify-center`}
        >
          <motion.div
            variants={modalVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            className="text-gray-800 antialiased justify-center items-center flex overflow-hidden fixed inset-0 z-50"
          >
            {/* <Elements stripe={stripePromise}> */}
            <div className="relative max-h-full w-98 px-4 overflow-y-auto">
              <div
                ref={ref}
                className="rounded-xl space-y-4 shadow-lg relative flex flex-col w-full bg-white overflow-y-auto p-6"
              >
                <div className="flex items-center justify-between">
                  <div className="font-semibold tracking-tight">
                    {t('payment_method')}
                  </div>
                  <button
                    type="button"
                    onClick={() => {
                      setPayment({ ...payment, modal: false, intent: null, edit: null });
                    }}
                    className="hover:opacity-75 outline-none focus:outline-none"
                  >
                    <FontAwesomeIcon icon={faTimesCircle} className="text-xl" />
                  </button>
                </div>
                <div>
                  <Form
                    className="grid grid-cols-1 md:grid-cols-2 gap-4 text-xs py-4 w-full"
                    name="stripePaymentForm"
                  >
                    <PaymentElement
                      options={{ clientSecret: payment.intent }}
                      className="col-span-1 md:col-span-2"
                    />
                    <Button
                      text={t('save')}
                      loading={loading}
                      onClick={handleStripe}
                      formName="stripePaymentForm"
                      textColor="text-white"
                      loaderColor="bg-white"
                      className="col-span-1 md:col-span-2 bg-accent hover:bg-accent"
                    />
                    {/* <button type="submit" disabled={!stripe}>Submit</button> */}
                  </Form>
                </div>
              </div>
            </div>
            {/* </Elements> */}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default Modal;
