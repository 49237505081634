import React, { useState } from 'react';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStore, faBus } from '@fortawesome/pro-duotone-svg-icons';
import { useTranslation } from 'react-i18next';
import { serviceSelectionAtom, serviceAtom } from '../../../../atoms/Atoms';
import { businessesSelector } from '../../../../atoms/Selectors';
import useAnalyticsEventTracker from '../../../../useAnalyticsEventTracker';

function ServiceSelection() {
const eventTracker = useAnalyticsEventTracker('User Actions');
const [serviceSelection, setServiceSelection] = useRecoilState(serviceSelectionAtom);
const [service, setService] = useRecoilState(serviceAtom);
const { t } = useTranslation();

// console.log('service', service);


    return (<div className="flex md:flex-row flex-col md:items-center space-between mt-4">         
                <div 
                  role="button"  
                  tabIndex={-1}            
                  onClick={() => {
                    setServiceSelection(true);
                    eventTracker('Service Selection', 'Pickup');
                  }}
                  className="bg-base flex flex-row rounded-xl border text-white p-4 w-full md:w-1/2 mr-4 shadow h-28 md:h-30"
                >
                  <FontAwesomeIcon className="text-4xl md:text-5xl mt-2 mr-6" icon={faStore} />                  
                  <div className="flex flex-col">
                    <div className="w-full font-semibold text-xs md:text-md">{t('pickup')}</div>
                    <div className="w-full font-light text-xs md:text-sm lg:text-md xl:text-lg overflow-hidden">{t('pickup_button_prompt')}
                    </div>
                  </div>
                </div>
                <div 
                  role="button"  
                  tabIndex={0}            
                  onClick={() => {
                    setServiceSelection(true);
                    eventTracker('Service Selection', 'Delivery');
                  }}
                  className="bg-base flex flex-row rounded-xl border text-white p-4 w-full md:w-1/2 my-4 md:my-2 md:ml-4 shadow h-28 md:h-30"
                >
                  <FontAwesomeIcon className="text-4xl md:text-5xl mt-2 mr-6" icon={faBus} />      
                  <div className="flex flex-col">
                    <div className="w-full font-semibold text-xs md:text-md">{t('delivery')}</div>
                    <div className="w-full font-light text-xs md:text-sm lg:text-md xl:text-lg overflow-hidden">{t('delivery_button_prompt')}
                    </div>
                  </div>
                </div>
            </div>)
}

export default ServiceSelection;
