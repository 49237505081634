import React, { useState, useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { notificationsAtom, orderAtom, modalAtom, productAtom } from '../../../../atoms/Atoms';
import envConfig from '../../../../envConfig';
import useAnalyticsEventTracker from '../../../../useAnalyticsEventTracker';
import Select from '../../form/Select';

function Requirements({ product }) {
  const eventTracker = useAnalyticsEventTracker('User Actions');
  const { t } = useTranslation();
  const [modal, setModal] = useRecoilState(modalAtom);
  const [productA, setProduct] = useRecoilState(productAtom);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 mb-2">
      {_.map(product.requirements, (req) => (
        <Select
          className="text-xs"
          label={req.name}
          id={req.id}
          name={req.id}
          value={req.selectedId}
          onChange={(obj) => {
            const requirements = JSON.parse(JSON.stringify(productA.requirements));
            let hasAllSelected = true;
            for (let i = 0; i < requirements.length; i++) {
              if (requirements[i].id === req.id) {
                requirements[i].selectedId = obj.target.value;
              }
              hasAllSelected = hasAllSelected && requirements[i].selectedId;
            }
            setProduct({
              ...productA,
              requirements,
              selectedAllRequirements: hasAllSelected
            });
          }}
          options={[{
            key: null,
            disabled: true,
            value: null,
            text: t('select_option'),
          }, ..._.map(req.items, (op) => ({
            key: op.id,
            value: op.id,
            text: op.name,
          }))]}
        />
      ))}
    </div>
  );
}

export default Requirements;
