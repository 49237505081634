import React, { useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-duotone-svg-icons';
import { Tab } from '@headlessui/react';
import { NavLink } from 'react-router-dom';
import { useClickAway } from 'react-use';
import { AnimatePresence, motion } from 'framer-motion';
import { modalAtom, productAtom } from '../../../../atoms/Atoms';
import Image from './Image';
import Price from './Price';
import SpecialPrice from './SpecialPrice';
import Related from './Related';
import BoughtTogether from './BoughtTogether';
import Counter from '../../Counter';
import { backdropVariants } from '../../../../utils';
import SpecialsBanner from '../../../../assets/img/specials/specials_banner.png';
import ItemTimeConstraint from '../../ItemTimeConstraint';
import Requirements from './Requirements';

function Modal() {
  const ref = useRef();
  const [showModal, setShowModal] = useRecoilState(modalAtom);
  const [product, setProduct] = useRecoilState(productAtom);

  useEffect(() => {
    if (!showModal) setProduct(null);
  }, [showModal]);

  useClickAway(ref, () => {
    setShowModal(false);
  });

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }  

  return (
    <AnimatePresence>
      {showModal && (
        <motion.div
          variants={backdropVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          style={{ zIndex: 1001 }}
          className="fixed bg-gray-800 inset-0 bg-opacity-75 z-10 flex items-end md:items-center justify-center px-0 md:px-4"
        >
          <div className="text-gray-800 antialiased font-sans justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 mx-auto w-full md:px-32 xl:w-2/3 px-4">
              <div
                ref={ref}
                className="border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-6"
              >
                <div className="flex items-center justify-end">
                  <button
                    type="button"
                    onClick={() => setShowModal(false)}
                    className="hover:opacity-75 outline-none focus:outline-none"
                  >
                    <FontAwesomeIcon icon={faTimesCircle} className="text-xl" />
                  </button>
                </div>
                <div className="flex space-x-4 md:mb-10">
                  {product && product.hasActivePromo && (
                    <div
                      className="w-1/2 md:w-5/8"
                      style={{ position: 'relative', top: 0, left: 0 }}
                    >
                      <img
                        className="h-5/8 w-3/4 mr-10 md:mt-8 text-xs"
                        alt={product.imageUrl}
                        style={{ position: 'relative', top: 25, left: 20 }}
                        name={product.name}
                        src={product.imageUrl}
                      />
                      <img
                        alt={product.name}
                        className="mt-0 md:mt-4"
                        style={{ position: 'absolute', top: 0, left: 0 }}
                        name={product.name}
                        src={SpecialsBanner}
                      />
                    </div>
                  )}
                  {product && !product.hasActivePromo && (
                    <div className="bg-white w-1/2">
                      <div className="lg:grid lg:grid-cols-1 lg:items-start lg:gap-x-8">
                        <Tab.Group as="div" className="flex flex-col-reverse">
                          <div className="mx-auto mt-6 hidden w-full max-w-2xl sm:block lg:max-w-none">
                            <Tab.List className="grid grid-cols-3 gap-6">
                              {product.images.map((image) => (
                                <Tab
                                  key={image.id}
                                  className="relative flex h-20 w-20 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                                >
                                  {({ selected }) => (
                                    <>
                                      <span className="sr-only">
                                        {image.name}
                                      </span>
                                      <span className="absolute inset-0 overflow-hidden rounded-md">
                                        <img
                                          src={image.url}
                                          alt=""
                                          className="h-1\/3 w-1\/3 object-cover object-center"
                                        />
                                      </span>
                                      <span
                                        className={classNames(
                                          selected
                                            ? 'ring-indigo-500'
                                            : 'ring-transparent',
                                          'pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2'
                                        )}
                                        aria-hidden="true"
                                      />
                                    </>
                                  )}
                                </Tab>
                              ))}
                            </Tab.List>
                          </div>

                          <Tab.Panels className="aspect-h-1 aspect-w-1 w-full">
                            {product.images.map((image) => (
                              <Tab.Panel key={image.id}>
                                <img
                                  src={image.url}
                                  alt={image.name}
                                  className="h-full w-full object-cover object-center sm:rounded-lg"
                                />
                              </Tab.Panel>
                            ))}
                          </Tab.Panels>
                        </Tab.Group>
                      </div>
                    </div>
                  )}
                  <div className="flex flex-col flex-1">
                    <div className="text-gray-500 text-xs mb-1">
                      {product.sectionName}
                    </div>
                    <div className="font-normal tracking-tight text-xs md:text-sm uppercase">
                      {product.brand}
                    </div>
                    <div className="font-extrabold tracking-tight text-xl md:text-3xl capitalize">
                      {_.toLower(product.name)}
                    </div>
                    <div className="capitalize font-light tracking-tight text-xs md:text-sm">
                      {_.toLower(product.description)}
                    </div>
                    {product.activePrice && !product.hasActivePromo && (
                      <Price
                        className="text-xs md:text-sm"
                        hasRandomWeight={product.hasRandomWeight}
                        weightType={product.weightType}
                        unitPriceFactor={product.unitPriceFactor}
                        price={product.activePrice}
                      />
                    )}
                    {product.activePrice && product.hasActivePromo && (
                      <SpecialPrice
                        className="text-xs md:text-sm"
                        hasRandomWeight={product.hasRandomWeight}
                        weightType={product.weightType}
                        isUnitPriceMethod={
                          product.baseUnitPriceMethod === 0 &&
                          product.promoUnitPriceMethod === 0
                        }
                        unitPriceFactor={product.unitPriceFactor}
                        price={product.activePrice}
                        savings={product.savings}
                        regularPrice={product.regularPrice}
                      />
                    )}
                    {product.activePrice && !product.isAvailable && (
                      <div className="mb-4">
                        <span className="text-xs font-semibold tracking-tight rounded-full bg-rose-200 text-rose-500 px-2 py-1 ring-2 ring-rose-500 ring-offset-2">
                          Not Available
                        </span>
                      </div>
                    )}
                    {!product.activePrice && (
                      <div className="mt-4">
                        <NavLink
                          to="/login"
                          onClick={() => {
                            setShowModal(false);
                          }}
                          className="bg-emerald-500 hover:bg-emerald-400 w-20 p-2 rounded font-semibold tracking-tight text-xs text-white outline-none focus:outline-none ring-0 focus:ring-0 transition-colors duration-500 ease-linear"
                        >
                          Iniciar Sesión
                        </NavLink>
                      </div>
                    )}
                    {product.activePrice && (
                      <div className="md:block">
                        <div className="text-gray-500 text-xs mb-4">
                          {product.description2}
                        </div>
                        {product?.requirements?.length > 0 && (
                          <Requirements product={product} />
                        )}
                        <Counter className="mx-auto block md:hidden" product={product} />
                        {/* <div className="text-gray-600 text-sm mt-4">
                          {`In Stock: ${product.quantityOnHand}`}
                        </div> */}
                        <ItemTimeConstraint product={product} />
                      </div>
                    )}
                  </div>
                </div>
                {product.activePrice && (
                  <>
                    <div className="block md:hidden mt-6">
                      {product.hasRandomWeight && (
                        <div className="text-gray-500 text-xs mb-4 hidden md:block">
                          Para los artículos cuyo precio es por libra, el precio
                          final se basará en peso real de los artículos
                          seleccionados.
                        </div>
                      )}
                      <ItemTimeConstraint product={product} />
                    </div>
                    {/* <Related product={product.id} /> */}
                    {/* <BoughtTogether product={product.id} /> */}
                  </>
                )}
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default Modal;
