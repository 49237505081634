import { Fragment, useState, React } from 'react'
import { Dialog, Popover, Tab, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next';
import { faBars, faShoppingBasket, faLanguage } from '@fortawesome/pro-duotone-svg-icons';
import { Button } from 'antd';
import GolfPlayer from '../../../../assets/img/golf.jpg';
import FeedbackFooter from '../../../shared-components/feedback-footer/FeedbackFooter';
import SocialFooter from '../../../shared-components/social-footer/SocialFooter';
import InfoFooter from '../../../shared-components/info-footer/InfoFooter';
import GopanzaFooter from '../../../shared-components/gopanza-footer/GopanzaFooter';
import HomeCategories from './HomeCategories';
import ServiceSelection from '../../../shared-components/service-selection/ServiceSelection';
import envConfig from '../../../../envConfig';
import LandingPage from './landing-page/LandingPage';
import FeaturedProduct from './FeaturedProduct';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

function Home2() {
    const { t } = useTranslation();
    return (
      <div className="overflow-auto">
        <div>
          <LandingPage />
          {/* <FeaturedProduct /> */}
          <HomeCategories />
        </div>
        <SocialFooter />
        <InfoFooter /> 
      </div>
    )
}

export default Home2;
