import React, { useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-duotone-svg-icons';
import { NavLink } from 'react-router-dom';
import { useClickAway } from 'react-use';
import { AnimatePresence, motion } from 'framer-motion';
import { modalAtom, productAtom } from '../../../atoms/Atoms';
import Image from './shared-components/Image';
import Price from './shared-components/Price';
import SpecialPrice from './shared-components/SpecialPrice';
import Related from './shared-components/Related';
import BoughtTogether from './shared-components/BoughtTogether';
import Counter from '../Counter';
import { backdropVariants } from '../../../utils';
import SpecialsBanner from '../../../assets/img/specials/specials_banner.png';
import ItemTimeConstraint from '../ItemTimeConstraint';
import envConfig from '../../../envConfig';
import ModalV2 from './shared-components/ModalV2';
import Requirements from './shared-components/Requirements';

function Modal() {
  const ref = useRef();
  const [showModal, setShowModal] = useRecoilState(modalAtom);
  const [product, setProduct] = useRecoilState(productAtom);

  useEffect(() => {
    if (!showModal) setProduct(null);
  }, [showModal]);

  useClickAway(ref, () => {
    setShowModal(false);
  });

  if (envConfig.StoreVersion === '2') {
    return (
      <div>
        <ModalV2 />
      </div>
    );
  }
  return (
    <AnimatePresence>
      {showModal && (
        <motion.div
          variants={backdropVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          style={{ zIndex: 1001 }}
          className="fixed bg-gray-800 inset-0 bg-opacity-75 z-10 flex items-end md:items-center justify-center px-0 md:px-4"
        >
          <div className="text-gray-800 antialiased font-sans justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 mx-auto w-full md:px-32 xl:w-2/3 px-4">
              <div
                ref={ref}
                className="border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-6"
              >
                <div className="flex items-center justify-end">
                  <button
                    type="button"
                    onClick={() => setShowModal(false)}
                    className="hover:opacity-75 outline-none focus:outline-none"
                  >
                    <FontAwesomeIcon icon={faTimesCircle} className="text-xl" />
                  </button>
                </div>
                <div className="flex space-x-4 md:mb-10">
                  {product && product.hasActivePromo && (
                    <div
                      className="w-1/2 md:w-5/8"
                      style={{ position: 'relative', top: 0, left: 0 }}
                    >
                      <img
                        className="h-5/8 w-3/4 mr-10 md:mt-8 text-xs"
                        alt={product.imageUrl}
                        style={{ position: 'relative', top: 25, left: 20 }}
                        name={product.name}
                        src={product.imageUrl}
                      />
                      <img
                        alt={product.name}
                        className="mt-0 md:mt-4"
                        style={{ position: 'absolute', top: 0, left: 0 }}
                        name={product.name}
                        src={SpecialsBanner}
                      />
                    </div>
                  )}
                  {product && !product.hasActivePromo && (
                    <Image name={product.name} image={product.imageUrl} />
                  )}
                  <div className="flex flex-col flex-1">
                    <div className="text-gray-500 text-xs">
                      {product.sectionName}
                    </div>
                    <div className="font-extrabold tracking-tight text-xl md:text-3xl capitalize">
                      {_.toLower(product.name)}
                    </div>
                    <div className="font-light tracking-tight text-xs md:text-sm">
                      {product.brand}
                    </div>
                    <div className="capitalize font-light tracking-tight text-xs md:text-sm">
                      {_.toLower(product.description)}
                    </div>
                    {product.activePrice && !product.hasActivePromo && (
                      <Price
                        className="text-xs md:text-sm"
                        hasRandomWeight={product.hasRandomWeight}
                        weightType={product.weightType}
                        unitPriceFactor={product.unitPriceFactor}
                        price={product.activePrice}
                      />
                    )}
                    {product.activePrice && product.hasActivePromo && (
                      <SpecialPrice
                        className="text-xs md:text-sm"
                        hasRandomWeight={product.hasRandomWeight}
                        weightType={product.weightType}
                        isUnitPriceMethod={
                          product.baseUnitPriceMethod === 0 &&
                          product.promoUnitPriceMethod === 0
                        }
                        unitPriceFactor={product.unitPriceFactor}
                        price={product.activePrice}
                        savings={product.savings}
                        regularPrice={product.regularPrice}
                      />
                    )}
                    {product.activePrice && !product.isAvailable && (
                      <div className="mb-4">
                        <span className="text-xs font-semibold tracking-tight rounded-full bg-rose-200 text-rose-500 px-2 py-1 ring-2 ring-rose-500 ring-offset-2">
                          No Disponible
                        </span>
                      </div>
                    )}
                    {product?.requirements?.length > 0 && (
                      <Requirements product={product} />
                    )}
                    {product.activePrice && (
                      <Counter
                        className="mx-auto block md:hidden"
                        product={product}
                      />
                    )}
                    {!product.activePrice && (
                      <div className="mt-4">
                        <NavLink
                          to="/login"
                          onClick={() => {
                            setShowModal(false);
                          }}
                          className="bg-emerald-500 hover:bg-emerald-400 w-20 p-2 rounded font-semibold tracking-tight text-xs text-white outline-none focus:outline-none ring-0 focus:ring-0 transition-colors duration-500 ease-linear"
                        >
                          Iniciar Sesión
                        </NavLink>
                      </div>
                    )}
                    {product.activePrice && (
                      <div className="hidden md:block">
                        {product.hasActivePromo && (
                          <div className="text-gray-500 text-sm mt-4">
                            Para los artículos cuyo precio es por libra, el
                            precio final se basará en peso real de los artículos
                            seleccionados.
                          </div>
                        )}
                        <ItemTimeConstraint product={product} />
                      </div>
                    )}
                  </div>
                </div>
                {product.activePrice && (
                  <>
                    <div className="block md:hidden mt-6">
                      {product.hasRandomWeight && (
                        <div className="text-gray-500 text-xs mb-4 hidden md:block">
                          Para los artículos cuyo precio es por libra, el precio
                          final se basará en peso real de los artículos
                          seleccionados.
                        </div>
                      )}
                      <ItemTimeConstraint product={product} />
                    </div>
                    <Related product={product.id} />
                    <BoughtTogether product={product.id} />
                  </>
                )}
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default Modal;
