import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import Golf from '../../../../../assets/img/landing-page/5.jpg';
import Pickleball from '../../../../../assets/img/landing-page/7.jpg';
import Tennis from '../../../../../assets/img/landing-page/12.jpg';
import pic from '../../../../../assets/img/market.jpg';
import './landingPage.css';
import HomeCategories from '../HomeCategories';

function LandingPage() {
  const [currentImage, setCurrentImage] = useState(0);

  const images = [Golf, Pickleball, Tennis];

  const products = [
    {
      id: 1,
      name: 'Handmade Bracelet',
      description: 'Beautifully crafted bracelet with intricate details.',
      price: '$29.99',
      image: pic,
    },
  ];

  const transition = { duration: 1.5, ease: 'easeInOut' };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 5000); // Change the duration as needed

    return () => clearInterval(interval);
  }, []);

  const handleImageClick = () => {
    // Add your custom logic when an image is clicked
    console.log(`Image ${currentImage + 1} clicked!`);
  };

  return (
    <div className="landing-page">
      <div className="carousel">
        {images.map((image, index) => (
          <motion.img
            src={image}
            alt={`carousel-image-${index}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: index === currentImage ? 1 : 0 }}
            exit={{ opacity: 0 }}
            transition={transition}
            className="carousel-image" // Add the class name here
            style={{ width: '100%', height: '100%', zIndex: index === currentImage ? 0 : -1 }}
            onClick={handleImageClick}
          />
        ))}
      </div>
    </div>
  );
}

export default LandingPage;
