import React, { useRef, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamation,
  faTimesCircle,
} from '@fortawesome/pro-duotone-svg-icons';
import { Link, useHistory } from 'react-router-dom';
import { useClickAway } from 'react-use';
import { AnimatePresence, motion } from 'framer-motion';
import {
  alertAtom,
  locationAtom,
  orderAtom,
  serviceAtom,
  sidebarAtom,
  storeAtom,
} from '../../../atoms/Atoms';
import Counter from '../counter/Counter';
import { backdropVariants, sidebarVariants } from '../../../utils';
import EmptyCart from '../illustrations/EmptyCart';
import { minimumSelector } from '../../../atoms/Selectors';
import envConfig from '../../../envConfig';
import SidebarV2 from './shared-components/SidebarV2';

function Sidebar() {
  const { t } = useTranslation();
  const ref = useRef();
  const history = useHistory();
  const [open, setOpen] = useRecoilState(sidebarAtom);
  const [order, setOrder] = useRecoilState(orderAtom);
  const store = useRecoilValue(storeAtom);
  const minimum = useRecoilValue(minimumSelector);
  const setAlert = useSetRecoilState(alertAtom);
  const service = useRecoilValue(serviceAtom);
  const location = useRecoilValue(locationAtom);

  const getTotal = (id) => {
    const filter = _.filter(order, (e) => e.id === id);
    const price = filter[0].activePrice;
    let amount = 0;
    for (let i = 0; i < filter.length; i++) {
      amount += filter[i].amount;
    }
    return {
      subtotal: price.toFixed(2),
      units: amount.toFixed(2),
      total: (price * amount).toFixed(2),
    };
  };

  const getOrderTotal = () => {
    let total = 0;
    _.forEach(order, (e) => {
      total += e.activePrice * e.amount;
    });
    return total.toFixed(2);
  };

  useClickAway(ref, () => {
    setOpen(false);
  });

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [open]);

  if (envConfig.SidebarVersion === '2') {
    return <SidebarV2 />
  }
  return (
    <>
      <AnimatePresence style={{ zIndex: 1000 }}>
        {open && (
          <motion.div
            variants={backdropVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            className={`fixed inset-0 bg-gray-800 bg-opacity-75 z-30 ${open ? 'block' : 'hidden'}`}
          >
            <motion.div
              ref={ref}
              variants={sidebarVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
              className={`absolute right-0 h-full flex flex-col w-full md:w-1/3 bg-white z-40 ${open ? 'translate-x-0' : 'translate-x-full'}`}
            >
              <div className="px-4 py-6 flex items-center justify-between bg-gray-100">
                <div className="text-xs tracking-tight text-gray-500">
                  {service === 0 ? (
                    <div className="text-gray-800 font-medium">
                      {t('pickup_at')}: {store && store.name}
                    </div>
                  ) : (
                    <div>
                      <div className="text-gray-800 font-medium">
                        {t('delivery_to')} {location.location?.name}
                      </div>
                      <div className="text-gray-800 font-medium">
                        {t('store')}: {store && store.name}
                      </div>
                    </div>
                  )}
                  <Link
                    onClick={() => setOpen(false)}
                    to={`/stores/${store && store.id}`}
                    className="text-xs text-accent hover:text-accent font-medium transform duration-500 ease-in-out"
                  >
                    {t('continue_shopping')}
                  </Link>
                </div>
                <button
                  type="button"
                  onClick={() => setOpen(false)}
                  className="hover:opacity-75 outline-none focus:outline-none"
                >
                  <FontAwesomeIcon icon={faTimesCircle} className="text-xl" />
                </button>
              </div>
              <div className="flex flex-col flex-1 overflow-auto p-4">
                {order.length > 0 && (
                  <div className="space-y-4">
                    {_.map(
                      _.orderBy(_.uniqBy(order, 'id'), 'name'),
                      (e, index) => (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{
                            duration: 1,
                            delay: 0.5,
                          }}
                          key={index}
                          className="flex items-center space-x-4"
                        >
                          <div className="flex items-center justify-center rounded-xl border border-gray-100 h-24 w-24">
                            <img
                              alt={e.name}
                              src={e.imageUrl}
                              className="h-16"
                            />
                          </div>
                          <div className="flex flex-col flex-1 text-xs">
                            <div className="w-full truncate font-bold tracking-tight capitalize">
                              {_.toLower(e.name)}
                            </div>
                            <div className="w-full truncate font-semibold tracking-tight text-xs">
                              ${getTotal(e.id).subtotal} x{' '}
                              {getTotal(e.id).units}{' '}
                              {e.hasRandomWeight &&
                                (getTotal(e.id).units === 1
                                  ? 'libra'
                                  : 'libras')}
                              {!e.hasRandomWeight &&
                                (getTotal(e.id).units === 1
                                  ? 'unidad'
                                  : 'unidades')}{' '}
                              = ${getTotal(e.id).total}
                            </div>
                            <div className="w-full truncate text-gray-500 font-light tracking-tight text-xs">
                              {e.brand}
                            </div>
                            <div className="w-full truncate text-gray-500 font-light tracking-tight capitalize text-xs">
                              {_.toLower(e.description)}
                            </div>
                          </div>
                          <Counter product={e} />
                        </motion.div>
                      )
                    )}
                  </div>
                )}
                {order.length === 0 && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{
                      duration: 1,
                      delay: 0.5,
                    }}
                    className="flex flex-col flex-1 items-center justify-center overflow-x-hidden"
                  >
                    {/* <EmptyCart className="h-32 mb-5" /> */}
                    <div className="text-gray-800 text-sm font-medium">
                      {t('empty_cart')}
                    </div>
                    <div className="text-gray-400 text-xs text-center">
                      {t('empty_cart_content')}
                      <br />
                      {t('empty_cart_prompt')}
                    </div>
                  </motion.div>
                )}
              </div>
              <AnimatePresence>
                {order.length > 0 && getOrderTotal() < minimum && (
                  <motion.div
                    initial={{ opacity: 0, y: 160 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 160 }}
                    transition={{
                      duration: 1,
                      delay: 1,
                      type: 'spring',
                    }}
                    className="flex items-center space-x-4 p-4 bg-gray-800 text-white text-xs"
                  >
                    <div className="flex flex-shrink-0 text-white flex items-center justify-center w-6 h-6 bg-rose-500 rounded-full">
                      <FontAwesomeIcon
                        icon={faExclamation}
                        className="text-xs"
                      />
                    </div>
                    <div>
                      {t('minimum_prompt')}{' '}
                      <span className="text-accent font-bold">
                        ${minimum.toFixed(2)}
                      </span>
                      , {t('you_have')}{' '}
                      <span className="text-accent font-bold">
                        ${getOrderTotal()}
                      </span>{' '}
                      {t('in_your_cart')}.
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
              <div className="bg-gray-100 flex flex-col md:flex-row md:items-center px-4 py-4 space-y-4 md:space-y-0 space-x-0 md:space-x-4">
                <button
                  type="button"
                  disabled={order.length === 0}
                  className="flex flex-1 items-center justify-center p-4 hover:shadow-md rounded-xl text-sm font-semibold tracking-tight text-gray-400 ring-2 ring-gray-200 ring-offset-2 bg-gray-200 hover:bg-gray-300 disabled:cursor-not-allowed disabled:opacity-50 outline-none focus:outline-none transition duration-500 ease-in-out"
                  onClick={() => {
                    setOpen(false);
                    setAlert({
                      show: true,
                      title: t('oops'),
                      content:
                        t('clear_cart_warning'),
                      onClick: () => {
                        setAlert({
                          show: false,
                        });
                        setOrder([]);
                        window.localStorage.removeItem(`${envConfig.StorageKey}-order`);
                      },
                    });
                  }}
                >
                  <span>{t('Empty_Cart')}</span>
                </button>
                <button
                  disabled={!store || getOrderTotal() < minimum}
                  type="button"
                  onClick={() => {
                    history.push('/checkout');
                    setOpen(false);
                  }}
                  className="flex flex-1 items-center justify-between p-4 hover:shadow-md rounded-xl text-sm font-semibold tracking-tight text-white ring-2 ring-accent ring-offset-2 bg-accent hover:bg-accent disabled:cursor-not-allowed disabled:opacity-50 outline-none focus:outline-none transition duration-500 ease-in-out"
                >
                  <div className="flex items-center justify-center h-6 w-6 bg-accent rounded-md">
                    <span className="text-xs">{order.length}</span>
                  </div>
                  <div className="w-1/2 text-center">{t('Complete_Order')}</div>
                  <div className="text-xs">${getOrderTotal()}</div>
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

export default Sidebar;
