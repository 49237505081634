import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMount } from 'react-use';
import { useRecoilValue } from 'recoil';
import { Spin } from 'antd';
import api from '../../../../api/api';
import { storeAtom } from '../../../../atoms/Atoms';
import envConfig from '../../../../envConfig';

function HomeCategories() {
  const { t } = useTranslation();
  const store = useRecoilValue(storeAtom);
  const [departments, setDepartments] = useState(null);
  const [loading, setLoading] = useState(false);



  useMount(() => {
    setLoading(true);
    api.get(`catalog/${envConfig.WebstoreId}`)
      .then((response) => {
        setDepartments(response.data.data.departments);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  });

  return (
    <div className="bg-white">
      <br />
      <div className="px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 xl:px-0">
        <h2 id="category-heading" className="text-2xl font-bold tracking-tight text-accent" style={{ margin: '20px' }}>
            {t('shop_by_department')}
        </h2>
        <a href={`/stores/${envConfig.WebstoreId}/departments`} className="hidden text-sm font-semibold text-accent hover:text-yellow-500 sm:block" style={{ margin: '10px' }}>
            {t('browse_all_departments')}
            <span aria-hidden="true"> &rarr;</span>
        </a>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        {loading ? <Spin /> : null}
      </div>
      <div className="mt-4 flow-root">
        <div className="-my-2" style={{ margin: '15px' }}>
          <div className="relative box-content h-80 overflow-x-auto py-2 xl:overflow-visible">
            <div className="absolute flex space-x-8 px-4 sm:px-6 lg:px-8 xl:relative xl:grid xl:grid-cols-5 xl:gap-x-8 xl:space-x-0 xl:px-0">
               {departments && departments.map((department) => (
                  <a
                    key={department?.name}
                    href={`/stores/${envConfig.WebstoreId}/departments/${department?.id}`}
                    className="relative flex h-80 w-56 flex-col overflow-hidden rounded-lg p-6 hover:opacity-75 xl:w-auto"
                  >
                    <span aria-hidden="true" className="absolute inset-0">
                      <img src={department?.imageUrl} alt="" className="h-full w-full object-cover object-center" />
                    </span>
                    <span
                      aria-hidden="true"
                      className="absolute inset-x-0 bottom-0 h-2/3
                       bg-gradient-to-t from-gray-800 opacity-50"
                    />
                    <span className="relative mt-auto text-center text-xl font-bold text-white">{department?.name}</span>
                  </a>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeCategories;
