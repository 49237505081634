import React, { useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { useRecoilState, useRecoilValue } from 'recoil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { backdropVariants } from '../../../../utils';
import Button from '../../../shared-components/buttons/Button';
import CheckoutItems from './CheckoutItems';
import OrderSummary from './OrderSummary';
import envConfig from '../../../../envConfig';
import Confirmation from './Confirmation';
import { storeAtom, userPaymentsAtom } from '../../../../atoms/Atoms';
import Payment from './Payment';
import { thousand, getRequirementsString } from '../../../../functions';

function Checkout() {
  const { t } = useTranslation();
  const ref = useRef();
  const [payment, setPayment] = useRecoilState(userPaymentsAtom);
  const [showSecondOrderAlertAfterSubmitPopup,
    setShowSecondOrderAlertAfterSubmitPopup]
    = useState(false);
  const [showSecondOrderAlertBeforeSubmitPopup,
      setShowSecondOrderAlertBeforeSubmitPopup]
      = useState(false);
  const history = useHistory();
  const store = useRecoilValue(storeAtom);

    return (
      <>
        <AnimatePresence>
          <div className="bg-white flex flex-col">
            <div className="mx-auto max-w-8xl px-4 pb-24 pt-20 sm:px-6 lg:max-w-7xl lg:px-4">
              <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t('shopping_cart')}</h1>
              <div>
                <button
                  type="button"
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                  style={{ float: 'right' }}
                  onClick={() => {
                    history.push(`/stores/${store.locations[0].id}`);
                  }}
                >
                  <span aria-hidden="true"> &larr;</span>
                  {t('continue_shopping')}
                </button>
              </div>
              <div className="mt-12 lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-12 xl:gap-x-16 overflow-y-auto">
                <CheckoutItems />
                <OrderSummary />
                <Confirmation />
              </div>
            </div>
            <AnimatePresence>
              {showSecondOrderAlertBeforeSubmitPopup && (
                <motion.div
                  variants={backdropVariants}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  style={{ zIndex: 1001 }}
                  className="fixed bg-gray-800 inset-0 bg-opacity-75 z-10 flex items-end md:items-center justify-center px-0 md:px-4"
                >
                <div className="text-gray-800 antialiased font-sans justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                  <div className="relative my-6 mx-auto w-full md:px-32 xl:w-2/3 px-4">
                    <div
                      ref={ref}
                      className="border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-6"
                    >
                      <div className="flex items-center justify-end">
                        <button
                          type="button"
                          onClick={() => setShowSecondOrderAlertBeforeSubmitPopup(false)}
                          className="hover:opacity-75 outline-none focus:outline-none"
                        >
                          <FontAwesomeIcon icon={faTimesCircle} className="text-xl" />
                        </button>
                      </div>
                      <div className="flex space-x-4">
                        <div className="font-light tracking-tight text-md md:text-lg">
                          {t('second_order_warning_before')}
                        </div>
                      </div>
                      <div className="flex items-center justify-end mt-4 space-x-2">
                        <Button
                          text={t('accept')}
                          onClick={() => setShowSecondOrderAlertBeforeSubmitPopup(false)}
                          className="h-10 w-full md:w-20 bg-green-500 text-white hover:bg-accent"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                </motion.div>
              )}
            </AnimatePresence>
        <AnimatePresence>
          {showSecondOrderAlertAfterSubmitPopup && (
            <motion.div
              variants={backdropVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
              style={{ zIndex: 1001 }}
              className="fixed bg-gray-800 inset-0 bg-opacity-75 z-10 flex items-end md:items-center justify-center px-0 md:px-4"
            >
              <div className="text-gray-800 antialiased font-sans justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative my-6 mx-auto w-full md:px-32 xl:w-2/3 px-4">
                  <div
                    ref={ref}
                    className="border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-6"
                  >
                    <div className="flex items-center justify-end">
                      <button
                        type="button"
                        onClick={() => setShowSecondOrderAlertAfterSubmitPopup(false)}
                        className="hover:opacity-75 outline-none focus:outline-none"
                      >
                        <FontAwesomeIcon icon={faTimesCircle} className="text-xl" />
                      </button>
                    </div>
                    <div className="flex space-x-4">
                      <div className="font-light tracking-tight text-md md:text-lg">
                        {t('second_order_warning_after')}
                      </div>
                    </div>
                    <div className="flex items-center justify-end mt-4 space-x-2">
                      <Button
                        text={t('accept')}
                        onClick={() => setShowSecondOrderAlertAfterSubmitPopup(false)}
                        className="h-10 w-full md:w-20 bg-green-500 text-white hover:bg-accent"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
  
          </div>
        </AnimatePresence>
        <AnimatePresence>
          {payment?.paypalUrl && (
            <motion.div
              variants={backdropVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
              style={{ zIndex: 1001 }}
              className="fixed bg-gray-800 inset-0 bg-opacity-75 z-10 flex items-end md:items-center justify-center px-0 md:px-4"
            >
              <div className="text-gray-800 antialiased font-sans justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative my-6 mx-auto w-full md:px-32 xl:w-2/3 px-4">
                  <div
                    ref={ref}
                    className="border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-6"
                  >
                    <div className="flex items-center justify-end">
                      <button
                        type="button"
                        onClick={() => {
                          setPayment({
                            ...payment,
                            paypalUrl: null
                          });
                        }}
                        className="hover:opacity-75 outline-none focus:outline-none"
                      >
                        <FontAwesomeIcon icon={faTimesCircle} className="text-xl" />
                      </button>
                    </div>
                    <div className="flex items-center justify-end mt-4 space-x-2">
                      <Button
                        text={t('click_to_redirect_to_paypal')}
                        onClick={() => {
                          const newWindow = window.open(
                            payment.paypalUrl,
                            '_blank',
                            'noopener,noreferrer'
                          );
                          if (newWindow) newWindow.opener = null;
                          setPayment({
                            ...payment,
                            paypalUrl: null
                          });
                        }}
                        className="h-10 w-full md:w-20 bg-green-500 text-white hover:bg-accent"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </>
  )
}
export default Checkout;
