import React, { useState } from 'react';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { useTranslation } from 'react-i18next';
import { serviceSelectionAtom, serviceAtom } from '../../../../atoms/Atoms';
import { ReactComponent as PickupImage } from '../../../../assets/img/icons/bag.svg';
import { ReactComponent as DeliveryImage } from '../../../../assets/img/icons/van.svg';
import useAnalyticsEventTracker from '../../../../useAnalyticsEventTracker';

function ServiceSelection() {
  const eventTracker = useAnalyticsEventTracker('User Actions');
  const [serviceSelection, setServiceSelection] = useRecoilState(serviceSelectionAtom);
  const [service, setService] = useRecoilState(serviceAtom);
  const { t } = useTranslation();


    return (
      <div className="flex flex-row md:items-center justify-between md:mt-4">         
        <div 
          role="button"  
          tabIndex={-1}          
          disabled  
          onClick={() => {
            setServiceSelection(true);
            eventTracker('Service Selection', 'Pickup');
          }}
          className="bg-accent flex flex-row items-center rounded-lg border text-white p-4 w-full md:w-1/2 my-4 md:my-2 md:ml-4 shadow h-auto md:h-16"
        >
          <PickupImage fill="white" className="h-8 md:h-10 block pr-2 md:px-2" />                 
          <div className="flex">
            <div className="w-full font-semibold text-lg md:text-2xl">{t('pickup')}</div>
          </div>
        </div>
        <div 
          role="button"  
          tabIndex={0}   
          disabled         
          onClick={() => {
            setServiceSelection(true);
            eventTracker('Service Selection', 'Delivery');
          }}
          className="bg-accent flex flex-row items-center rounded-lg border text-white p-4 w-full md:w-1/2 my-4 md:my-2 md:ml-4 shadow h-auto md:h-16 ml-2 md:ml-0"
        >
          <DeliveryImage fill="white" className="h-6 md:h-8 block pr-2 md:px-2" />      
          <div className="flex">
            <div className="w-full font-semibold text-lg md:text-2xl">{t('delivery')}</div>
          </div>
        </div>
      </div>)
}

export default ServiceSelection;
