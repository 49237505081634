import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookSquare,
  faInstagramSquare,
} from '@fortawesome/free-brands-svg-icons';
import Logo from '../../../assets/img/logo_footer.svg';

function GopanzaFooter() {
  return (
    <div className="flex flex-col items-center justify-center text-xs text-gray-500 p-2 bg-footerBackground">
      <div className="space-x-2 text-lg text-gray-400 hover:text-gray-600 transition duration-500 ease-in-out">
        <a
          target="_blank"
          hidden
          rel="noreferrer"
          href="https://m.facebook.com/Gopanza/"
        >
          <FontAwesomeIcon icon={faFacebookSquare} />
        </a>
        <a
          target="_blank"
          hidden
          rel="noreferrer"
          href="https://www.instagram.com/Gopanza/"
        >
          <FontAwesomeIcon icon={faInstagramSquare} />
        </a>
      </div>
      <div className="text-gray-400 mb-2 flex items-center">
        Powered by <img src={Logo} alt="Logo" className="w-20 h-auto" />
      </div>
      <div className="text-gray-400 font-light">
       <a 
         target="_blank"
         rel="noreferrer"
         href="https://www.gopanza.com/"
       >
        Copyright &copy; {new Date().getFullYear()} Gopanza LLC 
       </a>
      </div>
    </div>
  );
}

export default GopanzaFooter;
