import React from 'react';

function Form({ children, className, onSubmit, name }) {
  return (
    <form
      name={name}
      noValidate
      autoComplete="off"
      onSubmit={onSubmit}
      className={`${
        className && className
      } grid grid-cols-1 md:grid-cols-2 gap-4 text-xs py-4 w-full`}
    >
      {children}
    </form>
  );
}

export default Form;
