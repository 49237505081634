import { atom } from 'recoil';

export const themeAtom = atom({
  key: 'theme',
  default: 'light',
});

export const serviceAtom = atom({
  key: 'service',
  default: 0,
});

export const envAtom = atom({
  key: 'env',
  default: {
    timezone: 'America/Puerto_Rico',
  },
});

export const storeAtom = atom({
  key: 'store',
  default: null,
});

export const storesDialogAtom = atom({
  key: 'storesDialog',
  default: false,
});

export const serviceSelectionAtom = atom({
  key: 'serviceSelection',
  default: false,
});

export const departmentDrawerAtom = atom({
  key: 'departmentDrawer',
  default: false,
});

export const departmentsAtom = atom({
  key: 'departments',
  default: null,
});

export const departmentAtom = atom({
  key: 'department',
  default: null,
});

export const departmentPageAtom = atom({
  key: 'departmentPage',
  default: {
    category: null,
    SearchType: 2,
    sort: 3,
    page: 0,
    hasMore: true,
    Filters: [],
    products: [],
    loading: true,
    error: null,
  },
});

export const searchStringAtom = atom({
  key: 'searchString',
  default: '',
});

export const searchBrandAtom = atom({
  key: 'searchBrand',
  default: '',
});

export const searchPageAtom = atom({
  key: 'searchPage',
  default: {
    products: [],
    brands: [],
    loading: false,
  },
});

export const searchFiltersAtom = atom({
  key: 'searchFilters',
  default: null,
});

export const searchAtom = atom({
  key: 'search',
  default: '',
});

export const notificationsAtom = atom({
  key: 'notifications',
  default: [],
});

export const userAtom = atom({
  key: 'user',
  default: null,
});

export const isLoggingInAtom = atom({
  key: 'isLoggingIn',
  default: false,
});

export const tokenAtom = atom({
  key: 'token',
  default: null,
});

export const modalAtom = atom({
  key: 'modal', 
  default: false,
});

export const popupAtom = atom({
  key: 'popup',
  default: false,
});

export const productAtom = atom({
  key: 'product',
  default: null,
});

export const orderAtom = atom({
  key: 'order',
  default: [],
});

export const sidebarAtom = atom({
  key: 'sidebar',
  default: false,
});

export const alertAtom = atom({
  key: 'alert',
  default: {
    show: false,
    title: '',
    content: '',
    onClick: null,
  },
});

export const dayAtom = atom({
  key: 'day',
  default: '',
});

export const timeAtom = atom({
  key: 'time',
  default: null,
});

export const timeSlotAtom = atom({
  key: 'timeSlot',
  default: null
});

export const contactAtom = atom({
  key: 'contact',
  default: {
    name: '',
    phone: '',
    instructions: '',
  },
});

export const orderSuccess = atom({
  key: 'orderSuccess',
  default: false,
});

export const orderDetailsAtom = atom({
  key: 'orderDetails',
  default: null,
});

export const pageAtom = atom({
  key: 'page',
  default: 0,
});

export const sizeAtom = atom({
  key: 'size',
  default: 5,
});

export const successMessageAtom = atom({
  key: 'successMessage',
  default: null,
});

export const orderRefreshAtom = atom({
  key: 'orderRefresh',
  default: 0,
});

export const chatAtom = atom({
  key: 'chat',
  default: null,
});

export const orderDetailsModalAtom = atom({
  key: 'orderDetailsModal',
  default: null,
});

export const addProductsAtom = atom({
  key: 'addProducts',
  default: [],
});

export const addProductModalAtom = atom({
  key: 'addProductModal',
  default: null,
});

export const profileOrdersAtom = atom({
  key: 'profileOrders',
  default: null,
});

export const showAddProductsAtom = atom({
  key: 'showAddProducts',
  default: false,
});

export const searchAddProductsAtom = atom({
  key: 'searchAddProducts',
  default: '',
});

export const searchResultsAtom = atom({
  key: 'searchResults',
  default: [],
});

export const searchLoadingAtom = atom({
  key: 'searchLoading',
  default: false,
});


export const profilePaymentsAtom = atom({
  key: 'profilePayments',
  default: {
    page: 0,
    size: 5,
    orders: [],
    hasMore: true,
  },
});

export const locationAtom = atom({
  key: 'location',
  default: {
    modal: false,
    loading: true,
    current: 'locations',
    locations: [],
    location: null,
    loadingBusinesses: false,
    businesses: [],
    alert: null,
    page: 0,
  },
});

export const chooseLocationAtom = atom({
  key: 'chooseLocationAtom',
  default: false,
});

export const menuAtom = atom({
  key: 'menu',
  default: false,
});

export const referenceOrderAtom = atom({
  key: 'referenceOrder',
  default: null
});

export const isAdditionalItemOrderAtom = atom({
  key: 'isAdditionalItemOrder',
  default: false
});

export const orderHistoryAtom = atom({
  key: 'orderHistory',
  default: false
});

export const loyaltyPointsAtom = atom({
  key: 'loyaltyPoints',
  default: 0
});

export const selectedOrderAtom = atom({
  key: 'selectedOrder',
  default: null
});

export const refreshStoresAtom = atom({
  key: 'refreshStoresAtom',
  default: 0
});

export const stateAtom = atom({
  key: 'currentState',
  default: null,
});

export const catalogAtom = atom({
  key: 'catalog',
  default: null,
});

export const dynamicDepartmentNameAtom = atom({
  key: 'dynamicDepartmentName',
  default: null,
});

export const promoCodeAtom = atom({
  key: 'promoCode',
  default: 0
});

export const showShopperAtom = atom({
  key: 'showShopper',
  default: false,
});

export const itemInstructionsModalAtom = atom({
  key: 'itemInstructionsModal',
  default: {},
});

export const selectedShopperPageAtom = atom({
  key: 'selectedShopperPage',
  default: null,
});

export const shopperPagesAtom = atom({
  key: 'shopperPages',
  default: [],
});

export const userPaymentsAtom = atom({
  key: 'userPayments',
  default: {
    modal: false,
    edit: null,
    cards: [],
    card: null,
    loading: true,
    error: null,
    options: null,
  },
});
