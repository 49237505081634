import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Link, NavLink } from 'react-router-dom';

function InfoFooter() {
    const { t } = useTranslation();
    const links = [    
        {
          key: 1,
          text: t('privacy'),
          url: '/privacy',
          type: 'link'
        },
        // {
        //   key: 2,
        //   text: t('terms'),
        //   url: 'http://www.gopanza.com/legal/terms',
        //   type: 'link'
        // },
        {
            key: 3,
            text: t('contact_us'),
            url: 'mailto:info@tgt.store',
            type: 'mail'
          },
      ];
    return (<div className="bg-accent h-auto md:h-8 flex flex-col md:flex-row items-center justify-center px-2">
            {_.map(links, (e, index) => (
            <div
              key={index}
              className="md:ml-4 flex items-center text-white hover:underline text-sm font-regular tracking-tight transition duration-500 ease-in-out outline-none focus:outline-none ring-0 focus:ring-0"
            >
              <a href={e.url} target="_blank" rel="noreferrer" className="truncate">{e.text}</a>
            </div>
           ))}      
            </div>)
}
export default InfoFooter;
