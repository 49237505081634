import React from 'react';
import { useUnmount, useMount } from 'react-use';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { locationAtom, popupAtom, storesDialogAtom } from '../../../atoms/Atoms';
import envConfig from '../../../envConfig';
import Home1 from './shared-components/Home1';
import Home2 from './shared-components/Home2';
import Home3 from './shared-components/Home3';
import Home4 from './shared-components/Home4';
import LandingPage from './shared-components/landing-page/LandingPage';

function Home() {
  const setDialog = useSetRecoilState(storesDialogAtom);
  const [showPopup, setShowPopup] = useRecoilState(popupAtom);

  useUnmount(() => {
    setDialog(false);
  });

  useMount(() => {
    setShowPopup(true);
  });

  if (envConfig.HomeVersion === '1') {
    return <Home1 />
} if (envConfig.HomeVersion === '2') { 
  return <Home2 />
} 
if (envConfig.HomeVersion === '3') { 
  return <Home3 />
}
if (envConfig.HomeVersion === '4') { 
  return <Home4 />
}
return <Home1 />
}
export default Home;
