import React, { useState } from 'react';
import { Dialog } from '@headlessui/react'
import { useTranslation } from 'react-i18next';
import img1 from '../../../assets/img/landing-page/10.png';
import img2 from '../../../assets/img/landing-page/9.png';
import img3 from '../../../assets/img/landing-page/7.jpg';
import img4 from '../../../assets/img/landing-page/5.jpg';
import img5 from '../../../assets/img/landing-page/2.jpg';
import img6 from '../../../assets/img/landing-page/11.jpg';

function AboutUs() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const { i18n, t } = useTranslation();

  const stories = [
    {
      year: '2005',
      description: t('our_story_1')
    },
    {
      year: '2010',
      description: t('our_story_2')
    },
    {
      year: '2012',
      description: t('our_story_3')
    },
    {
      year: '2017',
      description: t('our_story_4')
    },
    {
      year: '2018',
      description: t('our_story_5')
    },
    {
      year: '2022',
      description: t('our_story_6')
    },
    
  ]

  return (
    <div className="overflow-y-auto">
      <main className="isolate">
        {/* Hero section */}
        <div className="relative isolate -z-10">
          <div className="overflow-hidden">
            <div className="mx-auto max-w-7xl px-6 pb-32 pt-36 sm:pt-60 lg:px-8 lg:pt-32">
              <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
                <div className="w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
                  <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                    Tour Golf & Tennis
                  </h1>
                  <p className="relative mt-6 text-xl leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
                    {t('about_us_intro')} 
                  </p>
                </div>
                <div className="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
                  <div className="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
                    <div className="relative">
                      <img
                        src={img1}
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                  <div className="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
                    <div className="relative">
                      <img
                        src={img2}
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                    <div className="relative">
                      <img
                        src={img3}
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                  <div className="w-44 flex-none space-y-8 pt-32 sm:pt-0">
                    <div className="relative">
                      <img
                        src={img4}
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                    <div className="relative">
                      <img
                        src={img5}
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Values section */}
        <div className="mx-auto mt-8 max-w-7xl px-6 sm:mt-40 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t('our_story')}</h2>
          </div>
          <dl className="mx-auto mt-8 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-8 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {stories.map((story) => (
              <div key={story.year}>
                <dt className="font-semibold text-gray-900">{story.year}</dt>
                <dd className="mt-1 text-gray-600">{story.description}</dd>
              </div>
            ))}
          </dl>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            {t('our_story_out')}
          </p>
        </div>
        {/* Image section */}
        <div className="mt-32 sm:mt-40 xl:mx-auto xl:max-w-7xl xl:px-8">
          <img
            src={img6}
            alt=""
            className="mx-auto aspect-[5/2] w-1/2 h-1/2 object-cover xl:rounded-3xl"
          />
        </div>
      </main>
      <br />
      <br />
    </div>
  )
}

export default AboutUs;
